var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "max-width"
  }, [_c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ValidUntilFrom).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ValidUntilFrom).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ValidUntilFrom), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.ValidUntilFrom).value"
    }
  })], 1), _c('v-col', {
    staticClass: "max-width"
  }, [_c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ValidUntilTo).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ValidUntilTo).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.ValidUntilTo), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.ValidUntilTo).value"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }